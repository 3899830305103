import * as React from "react";
import {
  Wrapper,
  EventWrapper,
  PdpWrapper,
  DescriptionWrapper,
  DownloaderWrapper,
  PhotosWrapper,
CarrouselWrapper2,
  TitleWrapper,
  ImageWrapper,
  MobileTile
} from "./Aone.module.css";
import { GrayTitle } from "./../../../../css/Title.module.css";
import Title from "../../../../components/Title";
import Carousel from 'react-multi-carousel';
import Aone1 from "../../../../res/Aone/exposition/aone1.jpg"
import Aone2 from "../../../../res/Aone/exposition/aone2.jpg"
import Aone3 from "../../../../res/Aone/exposition/aone3.jpg"
import Aone4 from "../../../../res/Aone/exposition/aone4.jpg"
import Aone5 from "../../../../res/Aone/exposition/aone5.jpg"
import Aone6 from "../../../../res/Aone/exposition/aone6.jpg"
import Aone7 from "../../../../res/Aone/exposition/aone7.jpg"
import Aone8 from "../../../../res/Aone/exposition/aone8.jpg"
import Aone9 from "../../../../res/Aone/exposition/aone9.jpg"
import Aone10 from "../../../../res/Aone/exposition/aone10.jpg"
import Aone11 from "../../../../res/Aone/exposition/aone11.jpg"
import Aone12 from "../../../../res/Aone/exposition/aone12.jpg"
import Aone13 from "../../../../res/Aone/exposition/aone13.jpg"
import Tile from "./../../../../res/Aone/exposition/aone-tile.png"


const infos ={
  photos: [
    { src: Aone2, name: "Aone" },
    { src: Aone8, name: "Aone" },
    { src: Aone9, name: "Aone" },
  ],
};

const Aone = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 428, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };
  return (
        <div className={Wrapper}>
      <div className={EventWrapper}>
        <img className={PdpWrapper} src={Tile} alt="aone"/>
        <div className={DescriptionWrapper}>
        <h1 className={TitleWrapper}>RETROSPECTIVE<h2 style={{paddingLeft: "16px"}}>AONE - Marseille - 2020</h2></h1>
        <br />
        <img className={MobileTile} src={Tile} alt="aone"/>
        <br />
        <p>
        Admired, respected and considered as a major artist of the movement, AONE has contributed - thanks to his wild, philosophical, mystical & spiritual creations - to the influence of this culture, well beyond the borders of the South Bronx. Born in the ghetto, his apprenticeship was done on the street and in the subway. It is in self-taught that he will conquer the art world. At the end of the 70's, graffiti was then an impetuous geyser that only asked to explode from this underground scene, in which the majority of gallery owners, curators and journalists, seemed to want to confine it. To the rhythm of hip hop and breakdance, there was then a whole generation of artists, spirited teenagers, who wanted to show what their art, not could be, but was. AONE was one of them, spinning his bombs in an energetic and contagious dance. Aerosol Expressionism" reflects the search for a symbiosis between his American culture and his African origins, an initiatory journey along the paths of a philosophy advocating unity (of peoples) and tolerance, through his own mythology (necessarily urban); a quest for the absolute and the ideal that are found in his creations with a style that is as enigmatic as it is magical.   </p>
          <br />
        </div>
      </div>
        <div className={PhotosWrapper}>
          <Title title={"Vues in situ"} />
          <div className={CarrouselWrapper2}>
          <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsive}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={true}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {infos.photos.map((image) => (
                <img
                  className={ImageWrapper}
                  key={image.name}
                  alt={image.name}
                  src={image.src}
                />
            ))}
          </Carousel>
          </div>
        </div>
        <br />
    </div>
  );
};

export default Aone;
